@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  /* background: #1c8ef9 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
}

.auth-wrapper-survey {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
}

.auth-inner-survey > form > h3 {
  text-align: center;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-inner-survey {
  width: 900px;
  margin: 100px;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.authenticated-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100%;
}

.authenticated-profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
}

.authenticated-inner {
  width: 80%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 2px;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px 15px 15px 15px;
  border-radius: 15px;
  transition: all 0.3s;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.authenticated-profile-inner {
  width: 80%;
  margin: 100px auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px 15px 15px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.authenticated-inner-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

video {
  width: max(40%, 250px);
  height: auto;
  border-radius: 15px;
}

.authenticated-inner-form {
  display: grid;
  grid-template-rows: 8fr 1fr;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
}

.radio-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

input[type=radio] {
  border: 0px;
  height: 2em;
}

.radio-text {
  margin: 0 10px;
  font-size: large;
}

.btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.form-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.header-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 80%;
  margin: 0 auto;
  /* margin-top: 10px; */
  padding: 10px 0px 10px 0px;
}



.header-text {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
  text-align: left;
}

.time-elapsed {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin: 0;
  text-align: right;
}

.nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  background: #ffffff;
  padding: 10px 15px 15px 15px;
  border-radius: 15px;
}

.profile-nav-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 100px auto;
  background: #ffffff;
  padding: 10px 15px 15px 15px;
  border-radius: 15px;
}

.footer-inner-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 2px 15px;
}

.footer-text {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.profile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 50px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  padding: 10px 0;
  margin-bottom: 0;
}

.subtitle {
  font-size: 14px;
  font-weight: 200;
  color: #b2b2b2;
  margin-bottom: 20px;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.continue-p{
  font-size: 12px;
    color: #b2b2b2;
    font-weight: 600;
    margin-top: 1rem;
    text-align: center;
}


/* switch */
.switch-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px; /* Reduce width by half */
  height: 17px; /* Reduce height by half */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.context-status {
  font-weight: normal; /* Remove bold styling */
  margin-left: 10px;   /* Add margin to create space */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 17px; /* Half of the height for a rounded slider */
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px; /* Reduce by half */
  width: 13px; /* Reduce by half */
  left: 3px; /* Adjust placement */
  bottom: 2px; /* Adjust placement */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(13px); /* Adjust translation for smaller slider */
}

/* checkbox */
.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.other-checkbox-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox-text {
  margin: 0 10px;
  font-size: large;
  width: 30%;
}

.slider-level {
  width: min(275px, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

/* other - text input */
.text-input {
  width: min(225px, 50%) !important ;
  margin: 10px 0;
}

/* buttons */
.btn-primary:hover {
  cursor: pointer;
}

.btn-secondary:hover {
  cursor: pointer;
}


/* thank you */
.auth-inner-thank-you {
  /* Keep the existing styles */
  width: 450px; /* Ensure appropriate width */
  text-align: center; /* Center-align text in the inner container */
}

.title-thank-you {
  /* Utilize existing styles */
  margin-top: 20px; /* Add top margin if necessary */
}

.subtitle-thank-you {
  /* Utilize existing styles */
  margin-bottom: 20px; /* Add bottom margin for space before any image/logo */
}


/* progress bar */
.progress-bar-info {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.progress-footer {
  text-align: right;
  width: 100%;
}

/* video */
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video-label {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin: 0;
  text-align: right;
}

.caution-audio {
  font-size: 18px;
  font-weight: 600;
  /* margin: 0; */
  text-align: center;
}

/* subsession */
.footer-inner-second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 0px 15px 15px 15px;
}

/* New styles for Welcome.js */
.auth-inner-welcome {
  width: 60%;
  max-width: 1000px;
  margin: 100px auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 60px;
  border-radius: 15px;
  transition: all 0.3s;
  text-align: center;
}

.welcome-title {
  font-size: 2rem;
  font-weight: 700;
  color: #1c8ef9;
  margin-bottom: 60px;
}

.welcome-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

.welcome-prompt {
  font-size: 1.1rem;
  color: #666;
  margin-top: 30px;
  margin-bottom: 20px;
}

.start-button {
  background-color: #1c8ef9;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #167bff;
}

ul {
  text-align: left;
  margin-left: 20px;
}

ul li {
  margin-bottom: 10px;
}